import {
    formatDateToLocalTime,
    parseTimeOfDay,
} from "../../../helpers/format_date"

const today = "2024-01-01"
const tomorrow = "2024-01-02"

function parseIntoDateForGraph(minutes: number, isToday: boolean) {
    const T = parseTimeOfDay(minutes)
    return isToday ? `${today} ${T}` : `${tomorrow} ${T}`
}

const parseFromDcu = (changes: any) => {
    const midDayT = 720
    const midDayStr = parseTimeOfDay(midDayT)
    let data = []
    if (!changes || changes.length === 0)
        return {
            t: data.map((v) => v.t),
            y: data.map((v) => v.y),
        }
    let c_t = null
    let c_v = changes[changes.length - 1]["value"]
    let midDayAdded = false
    for (let i = 0; i < changes.length; i++) {
        c_t = changes[i]["time"]
        if (c_t >= midDayT && !midDayAdded) {
            data.push({
                t: `${tomorrow} ${midDayStr}`,
                y: c_v,
            })
            data.push({
                t: `${today} ${midDayStr}`,
                y: c_v,
            })
            midDayAdded = true
        }
        data.push({
            t: `${parseIntoDateForGraph(c_t, midDayAdded)}`,
            y: c_v,
        })
        c_v = changes[i]["value"]
        data.push({
            t: `${parseIntoDateForGraph(c_t, midDayAdded)}:01`,
            y: c_v,
        })
    }
    if (!midDayAdded) {
        data.push({
            t: `${tomorrow} ${midDayStr}`,
            y: c_v,
        })
        data.push({
            t: `${today} ${midDayStr}`,
            y: c_v,
        })
    }
    //console.log(data)
    const orderedData = data.sort((a, b) =>
        a.t > b.t ? 1 : a.t < b.t ? -1 : 0
    )
    return {
        t: orderedData.map((v) => v.t),
        y: orderedData.map((v) => v.y),
    }
}

const isoToday = new Date().toISOString().substring(0, 10)

const isoYesterday = (() => {
    const referenceDate = new Date()
    referenceDate.setDate(referenceDate.getDate() - 1)
    return referenceDate.toISOString().substring(0, 10)
})()

const isoTomorrow = (() => {
    const referenceDate = new Date()
    referenceDate.setDate(referenceDate.getDate() + 1)
    return referenceDate.toISOString().substring(0, 10)
})()

const isoWeekAgo = (() => {
    const referenceDate = new Date()
    referenceDate.setDate(referenceDate.getDate() - 7)
    return referenceDate.toISOString().substring(0, 10)
})()

const timeFromDate = (dateValue: string) => {
    return formatDateToLocalTime(dateValue)
}

const timeToDate = (timeString: any) => `${isoToday}T${timeString}:00`

export {
    parseFromDcu,
    timeFromDate,
    timeToDate,
    isoToday,
    isoTomorrow,
    isoYesterday,
    isoWeekAgo,
}
