import React, { useState } from "react"
import { Link } from "react-router-dom"

//import component
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import LightDark from "../CommonForBoth/Menus/LightDark"

//import images
import logoSvg from "../../assets/images/logo-sm.svg"

//redux
import { useSelector } from "react-redux"

const Header = (props: any) => {
    const { layoutMode, showRightSidebar } = useSelector((state: any) => ({
        layoutMode: state.Layout.layoutMode,
        showRightSidebar: state.Layout.ShowRightSidebar,
    }))

    const [isClick, setClick] = useState<boolean>(true)

    /*** Sidebar menu icon and default menu set */
    function tToggle() {
        var body = document.body
        setClick(!isClick)
        if (isClick === true) {
            body.classList.add("sidebar-enable")
            document.body.setAttribute("data-sidebar-size", "sm")
        } else {
            body.classList.remove("sidebar-enable")
            document.body.setAttribute("data-sidebar-size", "lg")
        }
    }

    return (
        <React.Fragment>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box">
                            <Link to="/dashboard" className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src={logoSvg} alt="" height="24" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logoSvg} alt="" height="24" />{" "}
                                    <span className="logo-txt"></span>
                                </span>
                            </Link>

                            <Link to="/dashboard" className="logo logo-light">
                                <span className="logo-sm">
                                    <img src={logoSvg} alt="" height="24" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logoSvg} alt="" height="24" />{" "}
                                    <span className="logo-txt"></span>
                                </span>
                            </Link>
                        </div>

                        <button
                            onClick={() => {
                                tToggle()
                            }}
                            title="button"
                            type="button"
                            className="btn btn-sm px-3 font-size-16 header-item"
                            id="vertical-menu-btn"
                        >
                            <i className="fa fa-fw fa-bars"></i>
                        </button>
                    </div>

                    <div className="d-flex">
                        <LanguageDropdown />

                        {/* light / dark mode */}
                        <LightDark
                            layoutMode={layoutMode}
                            onChangeLayoutMode={props.onChangeLayoutMode}
                        />

                        <ProfileMenu />
                    </div>
                </div>
            </header>
        </React.Fragment>
    )
}

export default Header
