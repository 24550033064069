import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    Label,
    Input,
    FormFeedback,
    Form,
} from "reactstrap"
import PropTypes from "prop-types"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import avatar from "../../assets/images/users/avatar-1.jpg"

//redux
import { useSelector, useDispatch } from "react-redux"

import withRouter from "../../components/Common/withRouter"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

// actions
import { editProfile, resetProfileFlag } from "../../store/actions"

import { withTranslation } from "react-i18next"

const UserProfile = (props: any) => {
    const dispatch = useDispatch()

    const { error, success } = useSelector((state: any) => ({
        error: state.profile.error,
        success: state.profile.success,
    }))
    const [id, setId] = useState<number>(0)
    const [userName, setUserName] = useState<string>("")
    const [firstName, setFirstName] = useState<string>("")
    const [lastName, setLastName] = useState<string>("")
    const [phone, setPhone] = useState<string>("")

    useEffect(() => {
        const authUser: any = localStorage.getItem("authUser")
        if (authUser) {
            const obj = JSON.parse(authUser)

            setUserName(obj.userName)
            setFirstName(obj.firstName)
            setLastName(obj.lastName)
            setPhone(obj.phone)
            setId(obj.id)

            setTimeout(() => {
                dispatch(resetProfileFlag())
            }, 3000)
        }
    }, [dispatch, success])

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            userName: userName || "",
            firstName: firstName || "",
            lastName: lastName || "",
            phone: phone || "",
            id: id || 0,
        },
        validationSchema: Yup.object({
            //    username: Yup.string().required("Please Enter Your UserName"),
        }),
        onSubmit: (values) => {
            dispatch(editProfile(values))
        },
    })

    document.title = "Profile | LILA Controller admin"

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="User" breadcrumbItem="Profile" />

                    <Row>
                        <Col lg="12">
                            {error && error ? (
                                <Alert color="danger">{error}</Alert>
                            ) : null}
                            {success ? (
                                <Alert color="success">{success}</Alert>
                            ) : null}

                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="ms-3">
                                            <img
                                                src={avatar}
                                                alt=""
                                                className="avatar-md rounded-circle img-thumbnail"
                                            />
                                        </div>
                                        <div className="flex-grow-1 align-self-center ms-3">
                                            <div className="text-muted">
                                                <h5>
                                                    {firstName} {lastName}
                                                </h5>
                                                <p className="mb-1">
                                                    {userName}
                                                </p>
                                                <p className="mb-0">
                                                    {props.t("IdNo")}
                                                    {id}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <h4 className="card-title mb-4">
                        {props.t("ChangeProfile")}
                    </h4>

                    <Card>
                        <CardBody>
                            <Form
                                className="custom-form mt-4 pt-2"
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    validation.handleSubmit()
                                    return false
                                }}
                            >
                                <div className="form-group">
                                    <Input
                                        name="userName"
                                        value={validation.values.userName}
                                        type="hidden"
                                    />
                                    <Input
                                        name="id"
                                        value={validation.values.id}
                                        type="hidden"
                                    />

                                    <div className="mb-3">
                                        <Label className="form-label">
                                            {props.t("firstName")}
                                        </Label>
                                        <Input
                                            name="firstName"
                                            value={
                                                validation.values.firstName ||
                                                ""
                                            }
                                            type="text"
                                            placeholder={props.t(
                                                "EnterFirstName"
                                            )}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.firstName &&
                                                validation.errors.firstName
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.firstName &&
                                        validation.errors.firstName ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.firstName}
                                            </FormFeedback>
                                        ) : null}
                                    </div>

                                    <div className="mb-3">
                                        <Label className="form-label">
                                            {props.t("lastName")}
                                        </Label>
                                        <Input
                                            name="lastName"
                                            value={
                                                validation.values.lastName || ""
                                            }
                                            type="text"
                                            placeholder={props.t(
                                                "EnterLastName"
                                            )}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.lastName &&
                                                validation.errors.lastName
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.lastName &&
                                        validation.errors.lastName ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.lastName}
                                            </FormFeedback>
                                        ) : null}
                                    </div>

                                    <div className="mb-3">
                                        <Label className="form-label">
                                            {props.t("phone")}
                                        </Label>
                                        <Input
                                            name="phone"
                                            value={
                                                validation.values.phone || ""
                                            }
                                            type="text"
                                            placeholder={props.t("EnterPhone")}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.phone &&
                                                validation.errors.phone
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.phone &&
                                        validation.errors.phone ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.phone}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="text-center mt-4">
                                    <Button type="submit" color="danger">
                                        {props.t("ChangeProfile")}
                                    </Button>
                                </div>

                                <div className="text-center mt-4">
                                    <Link
                                        color="danger"
                                        to="/changepwd"
                                        className="btn btn-primary waves-effect waves-light"
                                    >
                                        {props.t("ChangePwd")}
                                    </Link>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withTranslation()(withRouter(UserProfile))

UserProfile.propTypes = {
    //history: PropTypes.object,
    t: PropTypes.any,
}
