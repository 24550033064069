const fakeStatusReport = [
    {
        id: "1",
        address: "selection-gsykp",
        location: "0",
        manufacturer: "Lu Ltd",
        power: 100,
        description: "single",
        lastSeen: "2023-08-01T12:33:12Z",
        lastDataReport: "2023-08-01T00:00:00Z",
        online: "online",
        totalOnTime: 1230,
    },
    {
        id: "1",
        address: "selection-gsykp",
        location: "0",
        manufacturer: "Lu Ltd",
        power: 100,
        description: "single",
        lastSeen: "2023-08-01T12:33:12Z",
        lastDataReport: "2023-08-01T00:00:00Z",
        online: "online",
        totalOnTime: 230,
    },
]

const fakeOperationalReport = [
    {
        id: "1",
        address: "selection-gsykp",
        location: "0",
        manufacturer: "Lu Ltd",
        power: 100,
        description: "single",
        workTime: 1000,
        offTime: 1000,
        onTime: 200,
        usage: 10,
    },
    {
        id: "1",
        address: "selection-gsykp",
        location: "0",
        manufacturer: "Lu Ltd",
        power: 100,
        description: "single",
        workTime: 1000,
        offTime: 1000,
        onTime: 200,
        usage: 10,
    },
]

export { fakeStatusReport, fakeOperationalReport }
