// gets the logged in user data from local session
const getLoggedInUser = () => {
    const user = localStorage.getItem("authUser")
    try {
        if (user) return JSON.parse(user)
    } catch (e) {
        console.error("Parsing user data error")
    }
    return null
}

//user is assemed logged in if there is any information in local storage
const isUserAuthenticated = () => {
    return getLoggedInUser() !== null
}

const getLoggedInUserForAuthApiCall = () => {
    const user = getLoggedInUser()
    if (user) {
        return [user.userName, user.token]
    }
    return ["", ""]
}

const createAuthHeader = (permissionName: string) => {
    const { userName, token } = JSON.parse(localStorage.getItem("authUser"))
    const myHeaders = new Headers()
    myHeaders.append("AuronAuth", `Bearer ${token}`)
    myHeaders.append("Permission", permissionName)
    myHeaders.append("UserName", userName)
    return {
        headers: myHeaders,
    }
}

export {
    createAuthHeader,
    getLoggedInUser,
    isUserAuthenticated,
    getLoggedInUserForAuthApiCall,
}
