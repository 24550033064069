import React from "react"

import { Routes, Route } from "react-router-dom"

import { userRoutes, authRoutes } from "./routes/allRoutes"

import Authmiddleware from "./routes/middleware/Authmiddleware"

import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

import "./assets/scss/theme.scss"
import "./assets/scss/preloader.scss"

import { fakeAuthBackend, fakeDataBackend } from "./helpers/fakeBackend"

import config from "./config"

if (config.isFakeAuth) {
    fakeAuthBackend()
}

if (config.isFakeBackend) {
    fakeDataBackend()
}

const App = () => {
    function getLayout() {
        return VerticalLayout
    }

    const Layout: any = getLayout()
    return (
        <React.Fragment>
            <Routes>
                {authRoutes.map((route, idx) => (
                    <Route
                        path={route.path}
                        element={
                            <NonAuthLayout>{route.component}</NonAuthLayout>
                        }
                        key={idx}
                    />
                ))}

                {userRoutes.map((route, idx) => (
                    <Route
                        path={route.path}
                        element={
                            <Authmiddleware>
                                <Layout>{route.component}</Layout>
                            </Authmiddleware>
                        }
                        key={idx}
                    />
                ))}
            </Routes>
        </React.Fragment>
    )
}

export default App
