import React from "react"

//i18n
import { withTranslation } from "react-i18next"

const LoadingErrorHttp = (props: any) => {
    const { permission, code, t  } = props;
    return (
        <>
            <h4 className="text-uppercase">{t("Error"+code)} - {t(permission)}</h4>
        </>
    )
}

export default withTranslation()(LoadingErrorHttp)
