import { LoginTypes } from "./actionTypes"

export const loginUser = (user: any, history: any) => {
    return {
        type: LoginTypes.LOGIN_USER,
        payload: { user, history },
    }
}

export const loginUserSuccess = (user: any) => {
    return {
        type: LoginTypes.LOGIN_USER_SUCCESS,
        payload: user,
    }
}

export const logoutUser = (history: any) => {
    return {
        type: LoginTypes.LOGOUT_USER,
        payload: { history },
    }
}

export const logoutUserSuccess = (response: any) => {
    return {
        type: LoginTypes.LOGOUT_USER_SUCCESS,
        payload: response,
    }
}

export const loginUserError = (error: any) => {
    return {
        type: LoginTypes.LOGIN_USER_ERROR,
        payload: error,
    }
}
export const logoutUserError = (error: any) => {
    return {
        type: LoginTypes.LOGOUT_USER_ERROR,
        payload: error,
    }
}

export const resetLoginFlag = () => {
    return {
        type: LoginTypes.RESET_LOGIN_FLAG,
    }
}
