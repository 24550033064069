import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { Link } from "react-router-dom"

// users
import user1 from "../../../assets/images/users/avatar-1.jpg"

//redux
import { useSelector } from "react-redux"

const ProfileMenu = (props: any) => {
    const { success } = useSelector((state: any) => ({
        success: state.profile.success,
    }))

    // Declare a new state variable, which we'll call "menu"
    const [menu, setMenu] = useState<boolean>(false)

    const [userName, setUserName] = useState("Admin")

    useEffect(() => {
        const { userName } = JSON.parse(localStorage.getItem("authUser"))
        setUserName(userName)
    }, [success])

    return (
        <React.Fragment>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className="btn header-item bg-soft-light border-start border-end"
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    <img
                        className="rounded-circle header-profile-user"
                        src={user1}
                        alt="Header Avatar"
                    />
                    <span className="d-none d-xl-inline-block ms-2 me-1">
                        {userName}
                    </span>
                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <Link to={"/profile"} className="dropdown-item">
                        <i className="bx bx-user font-size-16 align-middle me-1" />
                        {props.t("Profile")}
                    </Link>
                    <div className="dropdown-divider" />
                    <Link to="/logout" className="dropdown-item">
                        <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                        <span>{props.t("Logout")}</span>
                    </Link>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    )
}

ProfileMenu.propTypes = {
    success: PropTypes.any,
    t: PropTypes.any,
}

export default withTranslation()(ProfileMenu)
