import { call, put, takeEvery } from "redux-saga/effects"

import config from "../../../config"

// Login Redux States
import { LoginTypes } from "./actionTypes"
import {
    loginUserError,
    logoutUserError,
    loginUserSuccess,
    logoutUserSuccess,
} from "./actions"

import { postJwtLogin, postJwtLogout } from "../../../helpers/jwtbackend_helper"
import { getLoggedInUser } from "../../../helpers/logged_in_user"

function* loginUser({ payload: { user, history } }: any) {
    try {
        yield put(loginUserError(null)) //clear error
        const response: Promise<any> = yield call(postJwtLogin, {
            email: user.email,
            password: user.password,
            origin: config.origin,
        })
        localStorage.setItem("authUser", JSON.stringify(response))
        yield put(loginUserSuccess(response))
        history("/dashboard")
    } catch (error) {
        yield put(loginUserError(error))
        history("/login")
    }
}

function* logoutUser({ payload: { history } }: any) {
    yield put(logoutUserError(null)) //clear error
    const user = getLoggedInUser()
    if (!user) {
        history.push("/login")
        return
    }

    try {
        const response: Promise<any> = yield call(postJwtLogout)
        localStorage.removeItem("authUser")
        yield put(logoutUserSuccess(response))
        history.push("/logout")
    } catch (error) {
        yield put(logoutUserError(error))
    }
}

function* authSaga() {
    yield takeEvery(LoginTypes.LOGIN_USER, loginUser)
    yield takeEvery(LoginTypes.LOGOUT_USER, logoutUser)
}

export default authSaga
