import React from "react";
import { Container, Row, Col } from "reactstrap";
import Copyright from "../Common/Copyright"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Copyright />
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
