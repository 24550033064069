import axios from "axios"
import * as url from "./url_helper"
import {
    getLoggedInUserForAuthApiCall,
    isUserAuthenticated,
} from "./logged_in_user"

// Login Method
const postJwtLogin = async (data: any) => {
    return axios
        .post(url.POST_JWT_LOGIN, data)
        .then((response) => {
            if (response.status >= 200 || response.status <= 299)
                return response.data
            throw response.data
        })
        .catch((err) => {
            var message = err.message
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 404:
                        message = "Error404"
                        break
                    case 500:
                        message = "Error500"
                        break
                    case 401:
                        message = "Error401"
                        break
                }
            }
            throw message
        })
}

// Logout Method
const postJwtLogout = async () => {
    if (!isUserAuthenticated()) {
        console.warn("Logging out not authenticated user")
        localStorage.removeItem("authUser")
        return
    }
    const [userName, token] = getLoggedInUserForAuthApiCall()
    const data = { userName: userName, token: token }
    return axios
        .post(url.POST_JWT_LOGOUT, data)
        .then((response) => {
            if (response.status >= 200 || response.status <= 299) {
                localStorage.removeItem("authUser")
                return response.data
            }
            throw response.data
        })
        .catch((err) => {
            var message = err.message
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 404:
                        message = "Error404"
                        break
                    case 403:
                        message = "Error403"
                        break
                    case 500:
                        message = "Error500"
                        break
                }
            }
            throw message
        })
}

// Edit profile
const postJwtChangeUserData = async (data: any) => {
    ;[data.userName, data.token] = getLoggedInUserForAuthApiCall()
    return axios
        .post(url.POST_JWT_EDIT_PROFILE, data)
        .then((response) => {
            if (response.status >= 200 || response.status <= 299)
                return response.data
            throw response.data
        })
        .catch((err) => {
            var message = err.message
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 404:
                        message = "Error404"
                        break
                    case 403:
                        message = "Error403"
                        break
                    case 500:
                        message = "Error500"
                        break
                }
            }
            throw message
        })
}

// Check Perm
const postJwtPerm = async (data: any) => {
    ;[data.userName, data.token] = getLoggedInUserForAuthApiCall()
    return axios
        .post(url.POST_JWT_PERM, data)
        .then((response) => {
            if (response.status >= 200 || response.status <= 299)
                return response.data
            throw response.data
        })
        .catch((err) => {
            var message = err.message
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 404:
                        message = "Error404"
                        break
                    case 403:
                        message = "Error403"
                        break
                    case 500:
                        message = "Error500"
                        break
                }
            }
            return message
        })
}

// postChangePassword
const postJwtChangePassword = async (data: any) => {
    ;[data.userName, data.token] = getLoggedInUserForAuthApiCall()
    return axios
        .post(url.POST_JWT_CHANGE_PASSWORD, data)
        .then((response) => {
            if (response.status >= 200 || response.status <= 299)
                return response.data
            throw response.data
        })
        .catch((err) => {
            var message = err.message
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 404:
                        message = "Error404"
                        break
                    case 403:
                        message = "Error403"
                        break
                    case 500:
                        message = "Error500"
                        break
                }
            }
            throw message
        })
}

export {
    postJwtLogin,
    postJwtLogout,
    postJwtChangeUserData,
    postJwtChangePassword,
    postJwtPerm,
}
