export function getApiLink(suffix: string) {
    if (process.env.REACT_APP_AUTHBASEURL.endsWith("/"))
        return process.env.REACT_APP_AUTHBASEURL + suffix
    return process.env.REACT_APP_AUTHBASEURL + "/" + suffix
}

//LOGIN
export const POST_JWT_LOGIN = getApiLink("Login")

//LOGOUT
export const POST_JWT_LOGOUT = getApiLink("Logout")

//CHANGE PASSWORD
export const POST_JWT_CHANGE_PASSWORD = getApiLink("ChangePassword")

//PROFILE
export const POST_JWT_EDIT_PROFILE = getApiLink("ChangeUserData")

//Perm
export const POST_JWT_PERM = getApiLink("CheckPermission")

//OUR API
export const GET_DEVICE = "/api/device"
export const GET_DEVICES_LIST = "/api/devices"
export const GET_APP_CONFIG = "/api/global-map"

export const GET_PRESETS = "/api/presets"

export const GET_STATUS_REPORT = "/api/reports-status"
export const POST_EMAIL_STATUS_REPORT = "/api/email-status"
export const GET_OPERATIONAL_REPORT = "/api/reports-work"
export const POST_EMAIL_OPERATIONAL_REPORT = "/api/email-work"

export const GET_DCU_PENDING_CHANGES = "/api/pending-dcu"
export const GET_CONF_PENDING_CHANGES = "/api/pending-conf"
export const GET_BLOB = "/api/blob?name="

export const LIST_BLOBS = "/api/list-blobs"

export const POST_DEVICE_DCU = "/api/device?data=dcu&id="
export const POST_DEVICE_CONF = "/api/device?data=conf&id="
