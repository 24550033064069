import React from "react"
import { Navigate } from "react-router-dom"

//Dashboard
import Dashboard from "../pages/Dashboard"

//Messages
import Messages from "../pages/Messages"

//Reports
import StatusReport from "../pages/Reports/StatusReport"
import WorkingReport from "../pages/Reports/WorkingReport"

//ChangeLogs
import DcuLog from "../pages/ChangeLog/DcuLog"
import ConfLog from "../pages/ChangeLog/ConfLog"

//Authentication pages
import Login from "src/pages/Authentication/Login"
import Logout from "src/pages/Authentication/Logout"
import ChangePassword from "src/pages/Authentication/ChangePassword"
import UserProfile from "src/pages/Authentication/UserProfile"
import Presets from "src/pages/Presets/Presets"

interface RouteProps {
    path: string
    component: any
    exact?: boolean
}

const userRoutes: Array<RouteProps> = [
    //User Profile
    { path: "/profile", component: <UserProfile /> },
    { path: "/changepwd", component: <ChangePassword /> },

    //dashboard
    { path: "/dashboard", component: <Dashboard /> },

    //presets
    { path: "/presets", component: <Presets /> },

    //reports
    { path: "/reports", component: <StatusReport /> },
    { path: "/reports/operational", component: <StatusReport /> },
    { path: "/reports/working", component: <WorkingReport /> },

    //pending changes log
    { path: "/changes", component: <DcuLog /> },
    { path: "/changes/dcu", component: <DcuLog /> },
    { path: "/changes/conf", component: <ConfLog /> },

    //blob data
    { path: "/device-raw-messages", component: <Messages /> },

    // this route should be at the end of all other routes
    { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
]

const authRoutes: Array<RouteProps> = [
    //Authentication pages
    { path: "/login", component: <Login /> },
    { path: "/logout", component: <Logout /> },
]

export { userRoutes, authRoutes }
