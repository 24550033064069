import {ChangePasswordTypes} from "./actionTypes"

const initialState = {
  changeSuccessMsg: null,
  changeError: null,
}

const changePassword = (state = initialState, action : any) => {
  switch (action.type) {
    case ChangePasswordTypes.CHANGE_PASSWORD:
      state = {
        ...state,
        changeSuccessMsg: null,
        changeError: null,
      }
      break
    case ChangePasswordTypes.CHANGE_PASSWORD_SUCCESS:
      state = {
        ...state,
        changeSuccessMsg: action.payload,
      }
      break
    case ChangePasswordTypes.CHANGE_PASSWORD_ERROR:
      state = { ...state, changeError: action.payload }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default changePassword
