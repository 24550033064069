const deviceExample1 = {
    title: "Test device Example1",
    conf: {
        location: {
            lat: 52.232029,
            lon: 21.006191,
        },
        reporting: {
            type: 1,
            freq: 0,
            minuteOfDay: 0,
        },
        mode: 1,
        offset: {
            sunset: 30,
            sunrise: -30,
        },
        softwareVersion: "085",
        updateDate: "2023-08-20T17:26:00Z",
    },
    dcu: [
        { time: 390, value: 100 },
        { time: 432, value: 0 },
        { time: 445, value: 60 },
        { time: 720, value: 0 },
        { time: 750, value: 60 },
        { time: 780, value: 65 },
        { time: 840, value: 100 },
        { time: 870, value: 27 },
        { time: 1025, value: 100 },
        { time: 1269, value: 0 },
    ],
    info: {
        address: "Mocked",
        type: "Izylum",
        manufacturer: "Schreder",
        power: "62 W",
        description: null,
    },
    meta: {
        dcu: {
            lastDeviceUpdateTime: "2023-10-01T11:30:00Z",
            lastChangeRequestTime: "2023-10-04T11:30:00Z",
            presetId: 1,
        },
    },
    commands: [],
}

const deviceExample2 = {
    title: "Test device Example2",
    conf: {
        location: {
            lat: 52.232029,
            lon: 21.006191,
        },
        reporting: {
            type: 1,
            freq: 0,
            minuteOfDay: 0,
        },
        mode: 3,
        softwareVersion: "085",
        updateDate: "2023-08-20T17:26:00Z",
    },
    dcu: [],
    info: {
        address: "Example 2",
        type: "Izylum",
        manufacturer: "Schreder",
        power: "62 W",
        description: null,
    },
    meta: {
        dcu: {
            lastDeviceUpdateTime: "2023-10-01T11:30:00Z",
            lastChangeRequestTime: "2023-10-04T11:30:00Z",
        },
    },
    commands: [],
}

const deviceExample3 = {
    title: "Test device Example3",
    conf: {
        location: {
            lat: 52.232029,
            lon: 21.006191,
        },
        reporting: {
            type: 1,
            freq: 0,
            minuteOfDay: 0,
        },
        mode: 2,
        softwareVersion: "test3",
        updateDate: "2023-08-20T17:26:00Z",
    },
    dcu: [
        { time: 390, value: 100 },
        { time: 432, value: 0 },
    ],
    info: {
        address: "Example 3",
        type: "Izylum",
        manufacturer: "Schreder",
        power: "62 W",
        description: null,
    },
    meta: {
        dcu: {
            lastDeviceUpdateTime: "2023-10-01T11:30:00Z",
            lastChangeRequestTime: "2023-10-04T11:30:00Z",
        },
    },
    commands: [],
}

const devicesList = [
    {
        id: 175,
        location: "Bejsce",
        device: "868110060145560",
        lastSeen: "2023-09-16T19:15:08.1230136Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.238116929088015,
        lon: 20.59773382145911,
    },
    {
        id: 170,
        location: "Bejsce",
        device: "868110060144035",
        lastSeen: "2023-09-16T19:36:25.7599389Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23899073909463,
        lon: 20.599254728566176,
    },
    {
        id: 74,
        location: "Morawiany",
        device: "868110060142153",
        lastSeen: "2023-09-16T19:15:07.3418409Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.22094847889649,
        lon: 20.62647532111632,
    },
    {
        id: 159,
        location: "Bejsce",
        device: "868110060143672",
        lastSeen: "2023-09-17T04:03:47.635847Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24055213169785,
        lon: 20.59684749476001,
    },
    {
        id: 116,
        location: "Usciszowice",
        device: "868110060138300",
        lastSeen: null,
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23284015699458,
        lon: 20.642253281949827,
    },
    {
        id: 214,
        location: "Bejsce",
        device: "868110060141775",
        lastSeen: "2023-09-16T19:15:11.2323626Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23575323680212,
        lon: 20.595652691365437,
    },
    {
        id: 160,
        location: "Bejsce",
        device: "868110060138490",
        lastSeen: "2023-09-16T18:18:46.2189214Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24083011797223,
        lon: 20.59597878802444,
    },
    {
        id: 199,
        location: "Bejsce",
        device: "868110060144613",
        lastSeen: "2023-09-16T17:13:00.9808244Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.241132405659904,
        lon: 20.603648407184235,
    },
    {
        id: 83,
        location: "Stojanowice",
        device: "868110060141635",
        lastSeen: "2023-09-16T19:15:06.0604637Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.22637819471007,
        lon: 20.55823299083666,
    },
    {
        id: 137,
        location: "Bejsce",
        device: "868110060138813",
        lastSeen: "2023-09-16T19:15:06.1073821Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.244644574804994,
        lon: 20.598036355763245,
    },
    {
        id: 182,
        location: "Bejsce",
        device: "868110060140264",
        lastSeen: "2023-09-16T19:15:06.0135937Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23630083482471,
        lon: 20.59613681621658,
    },
    {
        id: 56,
        location: "Morawianki",
        device: "868110060142039",
        lastSeen: "2023-09-17T03:06:38.5427008Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.21580484982618,
        lon: 20.64542008674514,
    },
    {
        id: 168,
        location: "Bejsce",
        device: "868110060141387",
        lastSeen: "2023-09-17T04:05:25.9949191Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23950263661338,
        lon: 20.59914980973296,
    },
    {
        id: 151,
        location: "Bejsce",
        device: "868110060140108",
        lastSeen: "2023-09-16T19:15:08.2636205Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24184363127036,
        lon: 20.60037864551223,
    },
    {
        id: 154,
        location: "Bejsce",
        device: "868110060138409",
        lastSeen: "2023-09-16T19:15:13.6229821Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24236652105271,
        lon: 20.601712906245734,
    },
    {
        id: 200,
        location: "Bejsce",
        device: "868110060138284",
        lastSeen: "2023-09-16T17:11:31.5904877Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24145036474733,
        lon: 20.60374168484932,
    },
    {
        id: null,
        location: null,
        device: "868110060141791",
        lastSeen: "2023-09-17T04:04:48.1981519Z",
        status: "enabled",
        locationStatus: "unknown",
        lat: null,
        lon: null,
    },
    {
        id: 150,
        location: "Bejsce",
        device: "868110060141106",
        lastSeen: "2023-09-16T19:15:08.7167065Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24206844123353,
        lon: 20.60026355413845,
    },
    {
        id: 125,
        location: "Bejsce",
        device: "868110060141643",
        lastSeen: "2023-09-17T04:04:00.9014575Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.242947327315456,
        lon: 20.596396449913787,
    },
    {
        id: 15,
        location: "Stojanowice",
        device: "868110060138680",
        lastSeen: "2023-09-17T03:52:52.6534225Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.22655088337173,
        lon: 20.560914659028203,
    },
    {
        id: 191,
        location: "Bejsce",
        device: "868110060141494",
        lastSeen: "2023-09-16T19:15:06.1073821Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.2416186843268,
        lon: 20.60202129513793,
    },
    {
        id: 163,
        location: "Bejsce",
        device: "868110060138342",
        lastSeen: "2023-09-17T04:05:14.5262173Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24043877100145,
        lon: 20.59730757184963,
    },
    {
        id: 76,
        location: "Morawiany",
        device: "868110060146196",
        lastSeen: "2023-09-17T04:04:58.2606442Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.22239998880324,
        lon: 20.627996835227396,
    },
    {
        id: 45,
        location: "Bejsce",
        device: "868110060141825",
        lastSeen: "2023-09-17T03:50:49.7162894Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24798644519595,
        lon: 20.57786853987144,
    },
    {
        id: 103,
        location: "Usciszowice",
        device: "868110060142898",
        lastSeen: null,
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.2322252108551,
        lon: 20.643498480431955,
    },
    {
        id: 206,
        location: "Bejsce",
        device: "868110060145172",
        lastSeen: "2023-09-16T19:15:06.2635867Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.2396182630376,
        lon: 20.60578200146974,
    },
    {
        id: 18,
        location: "Stojanowice",
        device: "868110060143938",
        lastSeen: "2023-09-16T19:15:08.4198615Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.22562634782949,
        lon: 20.564767136198974,
    },
    {
        id: 135,
        location: "Bejsce",
        device: "868110060143466",
        lastSeen: "2023-09-16T17:13:12.6058651Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.244314077793646,
        lon: 20.59713864026287,
    },
    {
        id: 134,
        location: "Bejsce",
        device: "868110060138912",
        lastSeen: "2023-09-16T17:11:41.1997678Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24400415491508,
        lon: 20.596941027471303,
    },
    {
        id: 72,
        location: "Morawiany",
        device: "868110060139837",
        lastSeen: "2023-09-17T03:13:36.3228042Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.21946534650468,
        lon: 20.62481116597569,
    },
    {
        id: 152,
        location: "Bejsce",
        device: "868110060138714",
        lastSeen: "2023-09-16T20:10:05.0670882Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24227905742844,
        lon: 20.60090857013208,
    },
    {
        id: 190,
        location: "Bejsce",
        device: "868110060139217",
        lastSeen: "2023-09-16T19:15:06.216711Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24168719430337,
        lon: 20.60170939484209,
    },
    {
        id: 26,
        location: "Morawianki",
        device: "868110060144324",
        lastSeen: "2023-09-17T03:06:37.4958341Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.21566699598473,
        lon: 20.64420305727994,
    },
    {
        id: 1,
        location: "Grodowice",
        device: "868110060141601",
        lastSeen: "2023-09-16T19:15:06.216711Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.27052206026267,
        lon: 20.596569314950774,
    },
    {
        id: 209,
        location: "Bejsce",
        device: "868110060138789",
        lastSeen: "2023-09-16T18:27:37.1857209Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24011470510688,
        lon: 20.60453970428108,
    },
    {
        id: 203,
        location: "Bejsce",
        device: "868110060143144",
        lastSeen: "2023-09-16T19:15:07.3260829Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.239114460216406,
        lon: 20.60708801097477,
    },
    {
        id: 129,
        location: "Bejsce",
        device: "868110060141676",
        lastSeen: "2023-09-17T03:10:23.8076714Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24205995220365,
        lon: 20.59610702436621,
    },
    {
        id: 128,
        location: "Bejsce",
        device: "868110060138953",
        lastSeen: "2023-09-17T04:05:30.9011936Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.2423621432961,
        lon: 20.59620720057922,
    },
    {
        id: 17,
        location: "Stojanowice",
        device: "868110060143961",
        lastSeen: "2023-09-16T17:45:00.6461351Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.22584745987625,
        lon: 20.563886461299155,
    },
    {
        id: 25,
        location: "Morawianki",
        device: "868110060141080",
        lastSeen: "2023-09-17T03:06:50.7770643Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.21497552932591,
        lon: 20.64400677596702,
    },
    {
        id: 147,
        location: "Bejsce",
        device: "868110060141577",
        lastSeen: "2023-09-16T19:15:07.3260829Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.2427086440423,
        lon: 20.599935332325117,
    },
    {
        id: 132,
        location: "Bejsce",
        device: "868110060145420",
        lastSeen: "2023-09-16T19:15:06.0135937Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24368701531033,
        lon: 20.59679484446032,
    },
    {
        id: 71,
        location: "Morawianki",
        device: "868110060140009",
        lastSeen: "2023-09-17T03:06:38.5427008Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.22248983277459,
        lon: 20.63831759316414,
    },
    {
        id: 99,
        location: "Morawiany",
        device: "868110060138581",
        lastSeen: "2023-09-17T03:07:42.042519Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.22362404974493,
        lon: 20.628057344431,
    },
    {
        id: 162,
        location: "Bejsce",
        device: "868110060143532",
        lastSeen: "2023-09-17T04:05:41.8230313Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.2414357975572,
        lon: 20.595972096012037,
    },
    {
        id: 198,
        location: "Bejsce",
        device: "868110060140199",
        lastSeen: "2023-09-16T19:15:06.216711Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.240819413168445,
        lon: 20.603533076733022,
    },
    {
        id: 2,
        location: "Grodowice",
        device: "868110060139621",
        lastSeen: "2023-09-16T19:15:05.9198782Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.264170836711145,
        lon: 20.599825684557537,
    },
    {
        id: 19,
        location: "Stojanowice",
        device: "868110060139605",
        lastSeen: "2023-09-17T03:07:15.3238489Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.22236868448724,
        lon: 20.54552731049377,
    },
    {
        id: 184,
        location: "Bejsce",
        device: "868110060145446",
        lastSeen: "2023-09-16T19:15:06.1073821Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.240195924994616,
        lon: 20.59998597531997,
    },
    {
        id: 94,
        location: "Stojanowice",
        device: "868110060139589",
        lastSeen: "2023-09-17T03:07:07.214529Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.225174353306166,
        lon: 20.54406640768875,
    },
    {
        id: 185,
        location: "Bejsce",
        device: "868110060141007",
        lastSeen: "2023-09-16T19:15:07.3260829Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23989682930232,
        lon: 20.59980766217004,
    },
    {
        id: 178,
        location: "Bejsce",
        device: "868110060141858",
        lastSeen: "2023-09-16T19:15:07.3418409Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23743303301078,
        lon: 20.59689685936654,
    },
    {
        id: 149,
        location: "Bejsce",
        device: "868110060138326",
        lastSeen: "2023-09-16T19:15:06.2635867Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24223890088091,
        lon: 20.600178267660365,
    },
    {
        id: 173,
        location: "Bejsce",
        device: "868110060139084",
        lastSeen: "2023-09-16T19:15:06.216711Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23849891954537,
        lon: 20.59855447551498,
    },
    {
        id: 10,
        location: "Zbeltowice",
        device: "868110060138318",
        lastSeen: "2023-09-17T04:05:36.1667685Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.25153225327008,
        lon: 20.563720791334426,
    },
    {
        id: 165,
        location: "Bejsce",
        device: "868110060140082",
        lastSeen: "2023-09-17T04:03:49.9952508Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.240205126056445,
        lon: 20.598171173980568,
    },
    {
        id: 24,
        location: "Morawianki",
        device: "868110060143060",
        lastSeen: "2023-09-17T03:07:19.2302431Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.21189843372668,
        lon: 20.6454085649924,
    },
    {
        id: 169,
        location: "Bejsce",
        device: "868110060138797",
        lastSeen: "2023-09-16T20:10:03.4576748Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23931142492279,
        lon: 20.5994553902166,
    },
    {
        id: 176,
        location: "Bejsce",
        device: "868110060144001",
        lastSeen: "2023-09-16T19:15:06.3573673Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23792879132137,
        lon: 20.59737273908557,
    },
    {
        id: 161,
        location: "Bejsce",
        device: "868110060144373",
        lastSeen: "2023-09-17T03:29:51.416155Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24113632797326,
        lon: 20.59588133431194,
    },
    {
        id: 201,
        location: "Bejsce",
        device: "868110060141718",
        lastSeen: "2023-09-16T19:15:06.0604637Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.240463967714106,
        lon: 20.6036838242316,
    },
    {
        id: 77,
        location: "Morawiany",
        device: "868110060141437",
        lastSeen: "2023-09-17T03:15:37.7755306Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.22313543186814,
        lon: 20.628784949750816,
    },
    {
        id: 215,
        location: "Bejsce",
        device: "868110060141627",
        lastSeen: "2023-09-16T19:15:06.7167251Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.236017241997615,
        lon: 20.595887465570748,
    },
    {
        id: 208,
        location: "Bejsce",
        device: "868110060138193",
        lastSeen: "2023-09-16T19:15:06.4198677Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.239948209531136,
        lon: 20.60495145613225,
    },
    {
        id: 211,
        location: "Bejsce",
        device: "868110060143391",
        lastSeen: "2023-09-16T19:15:06.7167251Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23825480176828,
        lon: 20.598966885513573,
    },
    {
        id: 20,
        location: "Stojanowice",
        device: "868110060139878",
        lastSeen: "2023-09-17T03:07:04.1051659Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.22332954609913,
        lon: 20.54687180561808,
    },
    {
        id: 81,
        location: "Stojanowice",
        device: "868110060142369",
        lastSeen: "2023-09-17T03:07:04.1051659Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.221137988579386,
        lon: 20.544467670186325,
    },
    {
        id: 54,
        location: "Stojanowice",
        device: "868110060143987",
        lastSeen: "2023-09-16T19:15:07.3418409Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.22620817535741,
        lon: 20.562549041420656,
    },
    {
        id: 192,
        location: "Bejsce",
        device: "868110060144605",
        lastSeen: "2023-09-16T19:15:06.0135937Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.241392870065226,
        lon: 20.60233450327998,
    },
    {
        id: 5,
        location: "Grodowice",
        device: "868110060138821",
        lastSeen: "2023-09-16T19:15:07.3418409Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.26450142010504,
        lon: 20.588665436734928,
    },
    {
        id: 142,
        location: "Bejsce",
        device: "868110060138656",
        lastSeen: "2023-09-16T17:14:02.8868868Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24390568737712,
        lon: 20.59947402027221,
    },
    {
        id: 195,
        location: "Bejsce",
        device: "868110060138730",
        lastSeen: "2023-09-16T19:15:07.3418409Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24148438733759,
        lon: 20.600498790356244,
    },
    {
        id: 210,
        location: "Bejsce",
        device: "868110060139563",
        lastSeen: "2023-09-16T19:15:06.2635867Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23790817983012,
        lon: 20.59911601253819,
    },
    {
        id: 164,
        location: "Bejsce",
        device: "868110060139662",
        lastSeen: "2023-09-17T03:29:50.8067934Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.2403180791737,
        lon: 20.597786576379296,
    },
    {
        id: 55,
        location: "Morawianki",
        device: "868110060141262",
        lastSeen: "2023-09-17T03:07:08.0113701Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.21573455763513,
        lon: 20.644788675413803,
    },
    {
        id: 130,
        location: "Bejsce",
        device: "868110060138748",
        lastSeen: "2023-09-17T04:05:16.5730997Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24170570776416,
        lon: 20.59597191096628,
    },
    {
        id: 189,
        location: "Bejsce",
        device: "868110060143433",
        lastSeen: "2023-09-16T17:11:32.9342155Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.241289591000296,
        lon: 20.600446958258303,
    },
    {
        id: 127,
        location: "Bejsce",
        device: "868110060141668",
        lastSeen: "2023-09-17T03:20:14.4465539Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.242656296583306,
        lon: 20.59631583209736,
    },
    {
        id: null,
        location: null,
        device: "869777048877057",
        lastSeen: "2023-09-16T22:00:07.0008905Z",
        status: "enabled",
        locationStatus: "unknown",
        lat: null,
        lon: null,
    },
    {
        id: 139,
        location: "Bejsce",
        device: "868110060139258",
        lastSeen: "2023-09-16T19:15:05.9667138Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24474305570481,
        lon: 20.598715884404584,
    },
    {
        id: 101,
        location: "Usciszowice",
        device: "868110060143318",
        lastSeen: null,
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23381538672531,
        lon: 20.64025378384038,
    },
    {
        id: 212,
        location: "Bejsce",
        device: "868110060139720",
        lastSeen: "2023-09-16T17:13:19.9184037Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23856945752488,
        lon: 20.598978396182904,
    },
    {
        id: 84,
        location: "Kaczkowice",
        device: "868110060142591",
        lastSeen: "2023-09-17T04:04:16.4326786Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.21982626199468,
        lon: 20.5438663487221,
    },
    {
        id: 102,
        location: "Usciszowice",
        device: "868110060144209",
        lastSeen: null,
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23465428365689,
        lon: 20.63861644047938,
    },
    {
        id: 11,
        location: "Zbeltowice",
        device: "868110060138631",
        lastSeen: "2023-09-17T03:16:40.11911Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.25217382747318,
        lon: 20.564561781705983,
    },
    {
        id: 16,
        location: "Stojanowice",
        device: "868110060141411",
        lastSeen: "2023-09-17T03:50:28.372673Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.22611266330816,
        lon: 20.56330704620164,
    },
    {
        id: 75,
        location: "Morawiany",
        device: "868110060138474",
        lastSeen: "2023-09-16T17:13:00.2308443Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.2216666770856,
        lon: 20.627217946957845,
    },
    {
        id: 218,
        location: "Bejsce",
        device: "868110060141767",
        lastSeen: "2023-09-16T19:15:08.2636205Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.241753,
        lon: 20.601259,
    },
    {
        id: 171,
        location: "Bejsce",
        device: "868110060142062",
        lastSeen: "2023-09-16T17:11:26.2779771Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23879281223423,
        lon: 20.5991725330566,
    },
    {
        id: 179,
        location: "Bejsce",
        device: "868110060141700",
        lastSeen: "2023-09-16T17:11:21.2154886Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23714943214859,
        lon: 20.59672945058968,
    },
    {
        id: 157,
        location: "Bejsce",
        device: "868110060143755",
        lastSeen: "2023-09-17T04:05:10.9637053Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24091548627526,
        lon: 20.59578309531034,
    },
    {
        id: 146,
        location: "Bejsce",
        device: "868110060142633",
        lastSeen: "2023-09-16T19:15:08.9667054Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.242919354602726,
        lon: 20.59982559618872,
    },
    {
        id: 23,
        location: "Kijany",
        device: "868110060142245",
        lastSeen: "2023-09-16T19:15:06.2635867Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.21336729062881,
        lon: 20.619867860227384,
    },
    {
        id: 177,
        location: "Bejsce",
        device: "868110060138805",
        lastSeen: "2023-09-16T19:15:06.0135937Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23768846508374,
        lon: 20.59708337445154,
    },
    {
        id: 82,
        location: "Stojanowice",
        device: "868110060139407",
        lastSeen: "2023-09-16T19:15:07.3418409Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.22657954480827,
        lon: 20.55980351907892,
    },
    {
        id: 98,
        location: "Kijany",
        device: "868110060138896",
        lastSeen: "2023-09-16T17:11:31.637308Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.211355385873794,
        lon: 20.628588997848905,
    },
    {
        id: 145,
        location: "Bejsce",
        device: "868110060143342",
        lastSeen: "2023-09-16T19:15:06.0135937Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.2431394980239,
        lon: 20.5997220697614,
    },
    {
        id: 22,
        location: "Prokocice",
        device: "868110060141650",
        lastSeen: "2023-09-17T03:07:43.3393853Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.20426534299041,
        lon: 20.567893760897945,
    },
    {
        id: 138,
        location: "Bejsce",
        device: "868110060143169",
        lastSeen: "2023-09-16T19:22:19.1842652Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24462872366552,
        lon: 20.59850718173812,
    },
    {
        id: 124,
        location: "Bejsce",
        device: "868110060138441",
        lastSeen: "2023-09-17T04:05:39.5730057Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24327691304961,
        lon: 20.59606136078509,
    },
    {
        id: 4,
        location: "Grodowice",
        device: "868110060143136",
        lastSeen: "2023-09-16T19:15:06.0135937Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.26528737911319,
        lon: 20.5890024261117,
    },
    {
        id: 113,
        location: "Kijany",
        device: "868110060139423",
        lastSeen: "2023-09-16T19:30:14.5578989Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.21118987348862,
        lon: 20.629639280983923,
    },
    {
        id: 140,
        location: "Bejsce",
        device: "868110060139365",
        lastSeen: "2023-09-16T19:15:07.3260829Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24448056304457,
        lon: 20.599014760136285,
    },
    {
        id: 183,
        location: "Bejsce",
        device: "868110060141395",
        lastSeen: "2023-09-16T17:11:16.6061186Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23962804357065,
        lon: 20.59964639272691,
    },
    {
        id: 194,
        location: "Bejsce",
        device: "868110060143813",
        lastSeen: "2023-09-16T17:11:31.9341785Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24161533107912,
        lon: 20.60104330851832,
    },
    {
        id: 181,
        location: "Bejsce",
        device: "868110060141486",
        lastSeen: "2023-09-16T19:15:07.4357383Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23657440066096,
        lon: 20.596366405376635,
    },
    {
        id: 196,
        location: "Bejsce",
        device: "868110060139548",
        lastSeen: "2023-09-16T19:15:07.3573653Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24092005275152,
        lon: 20.602957941970445,
    },
    {
        id: 78,
        location: "Kaczkowice",
        device: "868110060144951",
        lastSeen: "2023-09-17T03:04:57.5742305Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.21054113456039,
        lon: 20.54033562627712,
    },
    {
        id: 213,
        location: "Bejsce",
        device: "868110060143862",
        lastSeen: "2023-09-16T17:14:25.9336982Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23548620195473,
        lon: 20.595416766255504,
    },
    {
        id: 126,
        location: "Bejsce",
        device: "868110060138615",
        lastSeen: "2023-09-17T04:03:21.4955981Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24314700424132,
        lon: 20.596401315490294,
    },
    {
        id: 188,
        location: "Bejsce",
        device: "868110060141239",
        lastSeen: "2023-09-16T19:15:05.9198782Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24103304165424,
        lon: 20.6003248870302,
    },
    {
        id: null,
        location: null,
        device: "868110060141452",
        lastSeen: "2023-09-16T19:15:07.3573653Z",
        status: "enabled",
        locationStatus: "unknown",
        lat: null,
        lon: null,
    },
    {
        id: 100,
        location: "Morawiany",
        device: "868110060138250",
        lastSeen: "2023-09-17T03:06:46.6207997Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.22945633114958,
        lon: 20.615124256791795,
    },
    {
        id: 27,
        location: "Morawianki",
        device: "868110060139530",
        lastSeen: "2023-09-17T03:06:41.5114475Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.21588091243383,
        lon: 20.646102081265237,
    },
    {
        id: 105,
        location: "Stojanowice",
        device: "868110060141890",
        lastSeen: "2023-09-17T03:07:07.4645143Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.224594341776765,
        lon: 20.546689011512083,
    },
    {
        id: 207,
        location: "Bejsce",
        device: "868110060138771",
        lastSeen: "2023-09-16T19:15:07.3418409Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23976664320318,
        lon: 20.605379838783094,
    },
    {
        id: 204,
        location: "Bejsce",
        device: "868110060138979",
        lastSeen: "2023-09-16T19:15:06.3573673Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23927183233869,
        lon: 20.60665983977372,
    },
    {
        id: 193,
        location: "Bejsce",
        device: "868110060143359",
        lastSeen: "2023-09-16T17:12:44.4652272Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24115095351081,
        lon: 20.60265727644689,
    },
    {
        id: 133,
        location: "Bejsce",
        device: "868110060142674",
        lastSeen: "2023-09-16T19:15:07.3260829Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24345990059258,
        lon: 20.5966321793092,
    },
    {
        id: 12,
        location: "Bejsce",
        device: "868110060138508",
        lastSeen: "2023-09-17T03:06:34.0896206Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23829885796314,
        lon: 20.58381718504501,
    },
    {
        id: 153,
        location: "Bejsce",
        device: "868110060140181",
        lastSeen: "2023-09-16T19:15:07.3418409Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24232775448669,
        lon: 20.601386171321497,
    },
    {
        id: 115,
        location: "Usciszowice",
        device: "868110060139167",
        lastSeen: null,
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.2333371364533,
        lon: 20.641255716295586,
    },
    {
        id: 136,
        location: "Bejsce",
        device: "868110060145339",
        lastSeen: "2023-09-16T19:15:06.0135937Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24459245921008,
        lon: 20.597546183849342,
    },
    {
        id: 46,
        location: "Bejsce",
        device: "868110060141122",
        lastSeen: "2023-09-17T03:47:34.0762139Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.247720056613886,
        lon: 20.57848104896159,
    },
    {
        id: 216,
        location: "Bejsce",
        device: "868110060139175",
        lastSeen: "2023-09-16T19:15:08.3260836Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.2421958045458,
        lon: 20.600462772103484,
    },
    {
        id: 166,
        location: "Bejsce",
        device: "868110060138524",
        lastSeen: "2023-09-17T04:05:11.5261917Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23999935953108,
        lon: 20.598543970705634,
    },
    {
        id: 217,
        location: "Bejsce",
        device: "868110060141163",
        lastSeen: "2023-09-16T17:11:48.1685023Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24163718392335,
        lon: 20.60046980019776,
    },
    {
        id: 70,
        location: "Morawianki",
        device: "868110060143557",
        lastSeen: "2023-09-17T03:06:13.7615235Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.22241208515472,
        lon: 20.637201268938952,
    },
    {
        id: 42,
        location: "Piotrkowice",
        device: "868110060141148",
        lastSeen: "2023-09-16T19:15:07.3418409Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24016297214109,
        lon: 20.63293727315617,
    },
    {
        id: 53,
        location: "Stojanowice",
        device: "868110060144217",
        lastSeen: "2023-09-16T19:15:06.0604637Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.226333087780745,
        lon: 20.56177086618295,
    },
    {
        id: 9,
        location: "Bejsce",
        device: "868110060138458",
        lastSeen: "2023-09-16T19:15:35.4197558Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.248262733100205,
        lon: 20.57723674299865,
    },
    {
        id: 93,
        location: "Stojanowice",
        device: "868110060139324",
        lastSeen: "2023-09-17T03:07:14.0270142Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.22489220589311,
        lon: 20.54536578846109,
    },
    {
        id: 167,
        location: "Bejsce",
        device: "868110060141171",
        lastSeen: "2023-09-17T03:12:39.1511159Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23975841840855,
        lon: 20.598854166969662,
    },
    {
        id: 148,
        location: "Bejsce",
        device: "868110060144811",
        lastSeen: "2023-09-16T21:20:06.8504552Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24249524442175,
        lon: 20.600045089990147,
    },
    {
        id: 197,
        location: "Bejsce",
        device: "868110060142583",
        lastSeen: "2023-09-16T19:15:07.3418409Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.240674154680455,
        lon: 20.60329646521266,
    },
    {
        id: 73,
        location: "Morawiany",
        device: "868110060145321",
        lastSeen: "2023-09-17T03:08:23.5111209Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.22018934239718,
        lon: 20.625693034697893,
    },
    {
        id: 174,
        location: "Bejsce",
        device: "868110060141429",
        lastSeen: "2023-09-16T19:15:06.0604637Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23832691076559,
        lon: 20.59819115511906,
    },
    {
        id: 202,
        location: "Bejsce",
        device: "868110060143045",
        lastSeen: "2023-09-16T18:12:22.3134302Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24028283796643,
        lon: 20.604090986316432,
    },
    {
        id: 144,
        location: "Bejsce",
        device: "868110060140777",
        lastSeen: "2023-09-16T19:15:07.3573653Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24335221423774,
        lon: 20.599609170026174,
    },
    {
        id: 180,
        location: "Bejsce",
        device: "868110060139472",
        lastSeen: "2023-09-16T19:46:48.3831707Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23689226545932,
        lon: 20.59657597064445,
    },
    {
        id: 172,
        location: "Bejsce",
        device: "868110060142385",
        lastSeen: "2023-09-16T19:15:07.3418409Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23869230853861,
        lon: 20.59887988453925,
    },
    {
        id: 41,
        location: "Piotrkowice",
        device: "868110060143326",
        lastSeen: "2023-09-17T03:03:24.2151082Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24080659735404,
        lon: 20.63399410085731,
    },
    {
        id: 85,
        location: "Stojanowice",
        device: "868110060138573",
        lastSeen: "2023-09-16T19:15:07.3730096Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.22608999615719,
        lon: 20.556889812365625,
    },
    {
        id: 21,
        location: "Prokocice",
        device: "868110060141197",
        lastSeen: "2023-09-16T17:13:31.1370572Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.20347388588187,
        lon: 20.56697811130253,
    },
    {
        id: 141,
        location: "Bejsce",
        device: "868110060143375",
        lastSeen: "2023-09-16T19:15:05.9198782Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.244207208998425,
        lon: 20.599283322818504,
    },
    {
        id: 205,
        location: "Bejsce",
        device: "868110060138672",
        lastSeen: "2023-09-16T19:15:06.0604637Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.23944726538102,
        lon: 20.606205574030184,
    },
    {
        id: 143,
        location: "Bejsce",
        device: "868110060145354",
        lastSeen: "2023-09-16T19:15:07.3418409Z",
        status: "enabled",
        locationStatus: "fixed",
        lat: 50.24358157688883,
        lon: 20.599644987003757,
    },
]

const globalConfig = {
    lat: 50.23999,
    lon: 20.60498,
    zoom: 13,
}

const presetsExample = {
    presets: [
        { label: `Domyślny`, value: "1", inUse: true },
        { label: `Nowy`, value: "2", inUse: false },
        { label: `Testowy`, value: "3", inUse: false },
        { label: `Pusty`, value: "4", inUse: false },
    ],
    dcus: [
        [
            { time: 0, value: 50 },
            { time: 240, value: 70 },
            { time: 300, value: 100 },
            { time: 1320, value: 70 },
        ],
        [
            { time: 300, value: 60 },
            { time: 360, value: 80 },
            { time: 1260, value: 60 },
            { time: 1290, value: 50 },
        ],
        [
            { time: 390, value: 100 },
            { time: 432, value: 0 },
            { time: 445, value: 60 },
            { time: 720, value: 0 },
            { time: 750, value: 60 },
            { time: 780, value: 65 },
            { time: 840, value: 100 },
            { time: 870, value: 27 },
            { time: 1025, value: 100 },
            { time: 1269, value: 0 },
        ],
        [],
    ],
}

export {
    deviceExample1,
    deviceExample2,
    deviceExample3,
    devicesList,
    globalConfig,
    presetsExample,
}
