import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { ProfileTypes } from "./actionTypes"
import { profileSuccess, profileError } from "./actions"

import { postJwtChangeUserData } from "../../../helpers/jwtbackend_helper"

function* editProfile({ payload: { user } }: any) {
    try {
        const response: Promise<any> = yield call(postJwtChangeUserData, {
            userName: user.userName,
            firstName: user.firstName,
            lastName: user.lastName,
            phone: user.phone,
            id: user.id,
        })
        const userold = JSON.parse(localStorage.getItem("authUser"))
        userold.phone = user.phone
        userold.firstName = user.firstName
        userold.lastName = user.lastName
        localStorage.setItem("authUser", JSON.stringify(userold))
        yield put(profileSuccess(response))
    } catch (error) {
        yield put(profileError(error))
    }
}

export function* watchProfile() {
    yield takeEvery(ProfileTypes.EDIT_PROFILE, editProfile)
}

function* ProfileSaga() {
    yield all([fork(watchProfile)])
}

export default ProfileSaga
