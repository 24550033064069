import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import {
    Alert,
    Col,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap"

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

import {
    formatLocalDateAsIsoString,
    parseMinuteOfDay,
    parseTimeOfDay,
} from "src/helpers/format_date"
import { validateDcu } from "./presets.service"
import {
    haspermManagePresets,
    haspermDisplayPresets,
    checkPermissions,
} from "src/helpers/permission_check"

const DiminityChanges = ({
    dcus,
    t,
    onSaveClick,
    onCloseClick,
    isEdit,
    isActive,
}: any) => {
    const [editedDcus, setEditedDcus] = useState<any>(dcus)
    const [hasPermissionsManage, setHasPermissionsManage] =
        useState<boolean>(false)
    const [hasPermissionsDisplay, setHasPermissionsDisplay] =
        useState<boolean>(false)

    if (!hasPermissionsManage)
        checkPermissions(haspermManagePresets, setHasPermissionsManage)
    if (!hasPermissionsDisplay)
        checkPermissions(haspermDisplayPresets, setHasPermissionsDisplay)

    return (
        <>
            <Row>
                <Label className="form-label" tag="h4">
                    {t("Changes in diminity")}
                </Label>
            </Row>
            <Row>
                <Col lg={6}>
                    <Label
                        htmlFor="time-local-input-from"
                        className="form-Label"
                    >
                        {t("Time")}
                    </Label>
                </Col>
                <Col lg={4}>
                    <Label className="form-label">{t("Diminity value")}</Label>
                </Col>
                <Col lg={2}></Col>
            </Row>
            {editedDcus.map((dcu: any, index: number) => (
                <Row key={index}>
                    <Col lg={6}>
                        <InputGroup>
                            <Flatpickr
                                className="form-control d-block"
                                placeholder={t("Select time")}
                                options={{
                                    enableTime: true,
                                    noCalendar: true,
                                    minuteIncrement: 30,
                                    dateFormat: "H:i",
                                    time_24hr: true,
                                }}
                                onChange={(e) => {
                                    if (e == null || e?.length == 0) {
                                        return
                                    }
                                    const value = formatLocalDateAsIsoString(
                                        e[0]
                                    )
                                    const timeOnlyValue = value.substring(
                                        11,
                                        16
                                    )
                                    setEditedDcus((prevItems) =>
                                        prevItems.map((item, localIndex) =>
                                            localIndex === index
                                                ? {
                                                      ...item,
                                                      time: parseMinuteOfDay(
                                                          timeOnlyValue
                                                      ),
                                                  }
                                                : item
                                        )
                                    )
                                }}
                                value={parseTimeOfDay(editedDcus[index]?.time)}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text">
                                    <i className="mdi mdi-clock-outline" />
                                </span>
                            </div>
                        </InputGroup>
                    </Col>
                    <Col lg={4}>
                        <Input
                            name="range_Value"
                            onChange={(e) => {
                                setEditedDcus((prevItems) =>
                                    prevItems.map((item, localIndex) =>
                                        localIndex === index
                                            ? {
                                                  ...item,
                                                  value: e.target.value,
                                              }
                                            : item
                                    )
                                )
                            }}
                            placeholder={t("Value from 0 to 100")}
                            type="number"
                            value={editedDcus[index]?.value}
                        />
                    </Col>
                    <Col lg={2}>
                        <button
                            type="button"
                            title="delete"
                            onClick={() => {
                                setEditedDcus((prevData) => {
                                    const newData = [...prevData]
                                    newData.splice(index, 1)
                                    return newData
                                })
                            }}
                            className="btn btn-danger waves-effect waves-light w-xs"
                        >
                            <i className="bx bx-trash label-icon"></i>
                        </button>
                    </Col>
                </Row>
            ))}
            <Row>
                <Col lg={4}></Col>
                <Col lg={6}></Col>
                <Col lg={2}>
                    {!haspermManagePresets ? null : (
                        <button
                            type="button"
                            title="add"
                            onClick={() => {
                                const newTime =
                                    editedDcus.length > 0
                                        ? editedDcus[editedDcus.length - 1].time
                                        : 0
                                const newValue =
                                    editedDcus.length > 0
                                        ? editedDcus[editedDcus.length - 1]
                                              .value
                                        : 100
                                const newRow = {
                                    time: newTime,
                                    value: newValue,
                                }
                                setEditedDcus((prevData) => [
                                    ...prevData,
                                    newRow,
                                ])
                            }}
                            className="btn btn-light waves-effect waves-light w-xs"
                        >
                            <i className="bx bx-plus label-icon"></i>{" "}
                        </button>
                    )}
                </Col>
            </Row>
            <hr />
            <Row>
                <Col>
                    <div className="text-end">
                        <button
                            type="button"
                            className="btn btn-light me-2 w-md"
                            onClick={onCloseClick}
                        >
                            {t("Close")}
                        </button>
                        {!hasPermissionsManage ? null : (
                            <button
                                type="button"
                                disabled={!isActive}
                                onClick={() => {
                                    onSaveClick(editedDcus)
                                }}
                                className="btn btn-success save-event w-md"
                            >
                                {isEdit ? t("Change") : t("Add")}
                            </button>
                        )}
                    </div>
                </Col>
            </Row>
        </>
    )
}

const EditPresetModal = ({
    show,
    isEdit,
    onSaveClick,
    onCloseClick,
    t,
    name,
    dcus,
}: any) => {
    const [editedName, setEditedName] = useState<string>(name)

    const [presetError, setPresetError] = useState<string | null>(null)

    const isValidName = !!editedName

    useEffect(() => {
        setEditedName(name)
    }, [name, isEdit])

    return (
        <Modal isOpen={show}>
            <ModalHeader toggle={onCloseClick} tag="h3">
                {!!isEdit ? t("Edit Preset") : t("Add Preset")}
            </ModalHeader>
            <ModalBody>
                {presetError != null ? (
                    <Row>
                        <Alert color="danger">{t(presetError)}</Alert>
                    </Row>
                ) : (
                    <></>
                )}
                <Row>
                    <Label htmlFor="new-preset-name" className="form-label">
                        {t("Name")}
                    </Label>
                    <Input
                        name="name"
                        className="form-inline"
                        id="new-preset-name"
                        onChange={(e: any) => {
                            setEditedName(e.target.value ?? "")
                        }}
                        value={editedName || name}
                    />
                </Row>
                <hr />
                <DiminityChanges
                    t={t}
                    dcus={dcus}
                    onSaveClick={(dcus: Array<any>) => {
                        //validate dcus values...
                        const validated = validateDcu(dcus)
                        //console.log(dcus)
                        if (validated) {
                            setPresetError(null)
                            onSaveClick(dcus, editedName)
                        } else {
                            setPresetError("Invalid schedule")
                        }
                    }}
                    isEdit={isEdit}
                    isActive={isValidName}
                    onCloseClick={() => {
                        setPresetError(null)
                        onCloseClick()
                    }}
                />
            </ModalBody>
        </Modal>
    )
}

EditPresetModal.propTypes = {
    onCloseClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    show: PropTypes.bool,
    isEdit: PropTypes.bool,
    name: PropTypes.string,
    t: PropTypes.any,
    dcus: PropTypes.array,
}

export default withTranslation()(EditPresetModal)
