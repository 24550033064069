import { combineReducers } from "redux"

// Layout
import Layout from "./layout/reducer";

//login
import login from "./auth/login/reducer";

// User Profile 
import profile from "./auth/profile/reducer";

// Change Password
import changePassword from "./auth/changePassword/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  login,
  profile,
  changePassword
})

export default rootReducer
