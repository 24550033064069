import React from "react"

//i18n
import { withTranslation } from "react-i18next"

import config from "src/config"

const version = config.version

const authorName = "AURON STUDIO"

const Copyright = (props: any) => {
    return (
        <p className="mb-0">
            © 2024 {props.t("Crafted")} {props.t("by")} {authorName} {version}
        </p>
    )
}

export default withTranslation()(Copyright)
