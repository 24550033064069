/**
 *  interface for Login Type
*/
export enum LoginTypes {
    LOGIN_USER = '@@login/LOGIN_USER',
    LOGIN_USER_SUCCESS = '@@login/LOGIN_SUCCESS',
    LOGIN_USER_ERROR = '@@login/LOGIN_ERROR',
    LOGOUT_USER = '@@login/LOGOUT_USER',
    LOGOUT_USER_SUCCESS = '@@login/LOGOUT_USER_SUCCESS',
    LOGOUT_USER_ERROR = '@@login/LOGOUT_ERROR',
    RESET_LOGIN_FLAG = '@@login/RESET_LOGIN_FLAG'
}
