const fakePendingDcuData = [
    {
        name: "dcu fake blob name 1",
        deviceId: "1",
        creationDate: "2023-10-21T17:07:00Z",
    },
    {
        name: "dcu fake blob name 1",
        deviceId: "2",
        creationDate: "2023-10-21T17:07:00Z",
    },
]

const fakePendingConfData = [
    {
        name: "conf fake blob name 1",
        deviceId: "1",
        creationDate: "2023-10-21T17:07:00Z",
    },
    {
        name: "conf fake blob name 2",
        deviceId: "2",
        creationDate: "2023-10-21T17:07:00Z",
    },
    {
        name: "conf fake blob name 3",
        deviceId: "3",
        creationDate: "2023-10-21T17:07:00Z",
    },
]
const fakeBlob = '{"EXAMPLE" : "Blob example as json"}'

const fakeBlobsList = ["fake 1", "fake 2", "fake 3"]

export { fakePendingDcuData, fakePendingConfData, fakeBlob, fakeBlobsList }
