import poland from "../assets/images/flags/poland.png"
import usFlag from "../assets/images/flags/us.jpg"

const languages: Object = {
    pl: {
        label: "Polski",
        flag: poland,
    },
    en: {
        label: "English",
        flag: usFlag,
    },
}

export default languages
