import React from "react"
import { withTranslation } from "react-i18next"
import { Alert } from "reactstrap"

const MailingResult = (props: any) => {
    if (props?.isSending) {
        return <Alert color="primary">{props.t("Sending an email")}...</Alert>
    }
    if (props?.error) {
        return (
            <Alert color="danger">
                {props.t("Error sending email")}: {props.error}
            </Alert>
        )
    }
    if (props?.result?.status == "sent") {
        return <Alert color="success">{props.t("Email sent")}</Alert>
    }
    return <></>
}

export default withTranslation()(MailingResult)
