import React, { useEffect, useState } from "react"
import { Collapse, Spinner, Alert } from "reactstrap"
import useFetch from "react-fetch-hook"
import LoadingErrorHttp from "../LoadingErrorHttp"
import { createAuthHeader } from "../../helpers/logged_in_user"
import { haspermAdmin, checkPermissions } from "../../helpers/permission_check"
//i18n
import { withTranslation } from "react-i18next"

import classnames from "classnames"

const BlobContent = ({ content, t, reloadParentHandler, type }) => {
    const [opened, setOpened] = useState(false)
    const [hasPermissions, setHasPermissions] = useState(false)
    const [deleteBlob, setDeleteBlob] = useState<boolean>(null)

    const blobName = content?.name ?? ""

    if (!hasPermissions) {
        checkPermissions(haspermAdmin, setHasPermissions)
    }

    const blobNameParam = encodeURIComponent(blobName)
    const {
        isLoading,
        data: blobContent,
        error,
    } = useFetch<any>(
        `/api/blob?name=${blobNameParam}&type=${type}`,
        createAuthHeader("admin")
    )

    const {
        data: deleteResult,
        isLoading: isDeleting,
        error: errorDelete,
    } = useFetch<any>(`/api/blob?name=${blobNameParam}&type=${type}`, {
        method: "DELETE",
        depends: [deleteBlob],
        ...createAuthHeader("admin"),
    })

    const setDeleteEvent = () => {
        console.log("Setting event for deletion...", blobNameParam)
        setDeleteBlob(true)
    }

    useEffect(() => {
        if (!!deleteResult && !isLoading && !error) {
            reloadParentHandler()
            setDeleteBlob(false)
        }
    }, [deleteBlob, deleteResult, isDeleting, errorDelete])

    if (isLoading || isDeleting) return <Spinner />

    if (!hasPermissions) {
        return <LoadingErrorHttp code="403" permission="admin" />
    }

    if (error) return <LoadingErrorHttp code="500" />

    const deviceId = content?.deviceId ?? ""

    const creationDate = content?.creationDate ?? ""

    return (
        <>
            <h2 className="accordion-header" id="headingOne">
                {hasPermissions ? (
                    <button
                        className={classnames("accordion-button", "fw-medium", {
                            collapsed: !opened,
                        })}
                        type="button"
                        onClick={() => {
                            setOpened(!opened)
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        <strong>{t("Device")}:</strong>
                        <span>{deviceId}</span>&nbsp;
                        <strong>{t("Change request time")}:</strong>
                        <span>{creationDate}</span>
                    </button>
                ) : null}
            </h2>

            <Collapse isOpen={opened} className="accordion-collapse">
                <div className="accordion-body">
                    <div className="text-muted">
                        <strong className="text-dark">
                            {JSON.stringify(blobContent) ?? t("No data")}
                        </strong>
                    </div>
                    <hr></hr>
                    {errorDelete ? (
                        <Alert color="danger">
                            {t("Error deleting command for device")}:&nbsp;
                            {deviceId}
                        </Alert>
                    ) : (
                        <></>
                    )}
                    <div className="text-muted">
                        {blobContent ? (
                            <button
                                type="button"
                                onClick={setDeleteEvent}
                                className="btn btn-danger waves-effect btn-label waves-light"
                            >
                                <i className="bx bx-trash label-icon"></i>
                                {t("Delete request")}
                            </button>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </Collapse>
        </>
    )
}

const Blob = (props: any) => {
    return (
        <div className="accordion" id="accordion">
            <div className="accordion-item">
                <BlobContent
                    content={props.content}
                    t={props.t}
                    type={props.type}
                    reloadParentHandler={props.reloadParentHandler}
                />
            </div>
        </div>
    )
}

export default withTranslation()(Blob)
