import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { ChangePasswordTypes } from "./actionTypes"
import { userChangePasswordSuccess, userChangePasswordError } from "./actions"

import { postJwtChangePassword } from "../../../helpers/jwtbackend_helper"

function* changePassword({ payload: { user, history } }: any) {
    try {
        const response: Promise<any> = yield call(postJwtChangePassword, {
            userName: user.userName,
            oldPassword: user.oldPassword,
            newPassword: user.newPassword,
            confirmPassword: user.confirmPassword,
        })
        yield put(userChangePasswordSuccess("Your password has been changed"))
    } catch (error) {
        yield put(userChangePasswordError(error))
    }
}

export function* watchUserPasswordChange() {
    yield takeEvery(ChangePasswordTypes.CHANGE_PASSWORD, changePassword)
}

function* changePasswordSaga() {
    yield all([fork(watchUserPasswordChange)])
}

export default changePasswordSaga
