const validateDcu = (changes: any) => {
    for (let i = 0; i < changes.length - 1; i++) {
        if (changes[i].time >= changes[i + 1].time) {
            return false
        }
    }
    return true
}

export { validateDcu }
