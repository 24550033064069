import React, { useState } from "react"
import { Navigate } from "react-router-dom"
import { useIdleTimer } from "react-idle-timer"

import config from "src/config"

const Authmiddleware = (props: any) => {
    const [appTimeout, setAppTimeout] = useState<boolean>(false)

    const handleOnIdle = () => {
        setAppTimeout(true)
    }

    useIdleTimer({
        timeout: 1000 * 60 * config.idleTimeoutMinutes,
        onIdle: handleOnIdle,
        debounce: 1000,
    })

    if (!localStorage.getItem("authUser")) {
        return <Navigate to={{ pathname: "/login" }} />
    }

    if (appTimeout) {
        return <Navigate to={{ pathname: "/logout" }} />
    }

    return <React.Fragment>{props.children}</React.Fragment>
}

export default Authmiddleware
