export const formatDateToLocalTime = function formatDateToLocalTime(
    utcString: string
) {
    if (!utcString) {
        return null
    }
    const utcDate = new Date(utcString)
    const localDateString = utcDate.toLocaleString()
    return localDateString
}

export const formatLocalDateAsIsoString = function formatLocalDateAsIsoString(
    dateValue: number
) {
    // Get the current local date
    const localDate = new Date(dateValue)

    const year = localDate.getFullYear()
    const month = localDate.getMonth()
    const day = localDate.getDate()
    const hours = localDate.getHours()
    const minutes = localDate.getMinutes()
    const seconds = localDate.getSeconds()

    const utcDate = new Date(
        Date.UTC(year, month, day, hours, minutes, seconds)
    )

    const utcISOString = utcDate.toISOString()

    return utcISOString
}

export const parseTimeOfDay = (minuteOfDay: number) => {
    const hourOfADay = Math.floor(minuteOfDay / 60)
    const minute = minuteOfDay - 60 * hourOfADay
    return (
        String(hourOfADay).padStart(2, "0") +
        ":" +
        String(minute).padStart(2, "0")
    )
}

export const parseMinuteOfDay = (timeOfDayStr: string) => {
    const components = timeOfDayStr.split(":")
    if (!components || components.length === 0) {
        return null
    }
    return parseInt(components[0]) * 60 + parseInt(components[1])
}
