import {ChangePasswordTypes} from "./actionTypes"

export const userChangePassword = (user : any, history : any) => {
  return {
    type: ChangePasswordTypes.CHANGE_PASSWORD,
    payload: { user, history },
  }
}

export const userChangePasswordSuccess = (message : string) => {
  return {
    type: ChangePasswordTypes.CHANGE_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const userChangePasswordError = (message : any) => {
  return {
    type: ChangePasswordTypes.CHANGE_PASSWORD_ERROR,
    payload: message,
  }
}
