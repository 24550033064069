import { postJwtPerm } from "./jwtbackend_helper"

const hasperm = async (permname: string) => {
    console.log("postJwtPerm (%s)", permname)
    const response = await postJwtPerm({ Permission: permname })
    if (response === true) {
        console.log("has permname (%s)", permname)
        return true
    } else {
        console.log("has not permname (%s)", permname)
        return false
    }
}

const haspermAdmin = () => {
    const permname = "admin"
    return hasperm(permname)
}

const haspermDisplayPresets = () => {
    const permname = "displayPresets"
    return hasperm(permname)
}

const haspermManagePresets = () => {
    const permname = "managePresets"
    return hasperm(permname)
}

const haspermDisplayDevices = () => {
    const permname = "displayDevices"
    return hasperm(permname)
}

const haspermManageDevices = () => {
    const permname = "manageDevices"
    return hasperm(permname)
}

const haspermDisplayMaps = () => {
    const permname = "displayMaps"
    return hasperm(permname)
}

const haspermSendMailReports = () => {
    const permname = "sendMailReports"
    return hasperm(permname)
}

const haspermDisplayReports = () => {
    const permname = "displayReports"
    return hasperm(permname)
}

const checkPermissions = async (
    haspermAction: any,
    setHasPermissions: React.Dispatch<React.SetStateAction<boolean>>
) => {
    const hasPermissions = await haspermAction()
    if (hasPermissions === true) {
        setHasPermissions(true)
    } else if (hasPermissions === false) setHasPermissions(false)
}

export {
    haspermDisplayPresets,
    haspermManagePresets,
    haspermAdmin,
    haspermDisplayDevices,
    haspermManageDevices,
    haspermDisplayMaps,
    haspermSendMailReports,
    haspermDisplayReports,
    checkPermissions,
}
