import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import { HashRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { configureStore } from "./store/index"

const getId: any = document.getElementById("root")
const root = ReactDOM.createRoot(getId)
root.render(
    <Provider store={configureStore({})}>
        <HashRouter>
            <App />
        </HashRouter>
    </Provider>
)
