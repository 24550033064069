import ReactApexChart from "react-apexcharts"
import React from "react"
import { formatLocalDateAsIsoString } from "src/helpers/format_date"

const PresetChart = (props: any) => {
    const series = [
        {
            name: props.t("Diminity chart"),
            type: "area",
            data: props.data?.y ?? [],
        },
    ]
    const options: Object = {
        chart: {
            stacked: false,
            toolbar: {
                show: false,
            },
            selection: {
                enabled: false,
            },
            zoom: {
                enabled: false,
            },
        },
        stroke: {
            width: [0],
            curve: "smooth",
        },
        plotOptions: {},
        colors: ["#556ee6"],
        fill: {
            opacity: [0.85],
        },
        labels: props.data?.t ?? [],
        markers: {
            size: 0,
        },
        legend: {
            offsetY: 0,
        },
        xaxis: {
            type: "datetime",
            labels: {
                datetimeUTC: false,
                datetimeFormatter: {
                    day: "HH:mm", //cheating to not show days on xaxis ticks etc
                    hour: "HH:mm",
                },
            },
        },
        yaxis: {
            min: 0,
            max: 100,
            title: {
                text: props.t("Diminity Percentage"),
            },
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (y: number) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0)
                    }
                    return y
                },
            },
            x: {
                formatter: function (x: number) {
                    if (typeof x !== "undefined") {
                        return formatLocalDateAsIsoString(x).slice(11, 16)
                    }
                    return x
                },
            },
        },
        grid: {
            borderColor: "#f1f1f1",
        },
    }

    return (
        <ReactApexChart
            options={options}
            series={series}
            type="line"
            height={props.height ?? "470"}
            className="apex-charts"
        />
    )
}

export default PresetChart
