import React, { Component } from "react"
import { FormGroup, Label, Row, Spinner } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

const RawPostFormStatus = ({
    isLoading,
    isError,
    lastDeviceUpdateTimeMsg,
    lastChangeRequestTimeMsg,
    t,
}: any) => {
    const msgStyle = isError ? "text-danger" : "text-success"
    return (
        <>
            <Row>
                <FormGroup className="mb-3">
                    <Label>{t("Device update time")}</Label>
                    &nbsp;
                    {isLoading ? (
                        <Spinner size="xs" />
                    ) : (
                        <>
                            :&nbsp;
                            <span className={msgStyle}>
                                {lastDeviceUpdateTimeMsg}
                            </span>
                        </>
                    )}
                </FormGroup>
            </Row>
            <Row>
                <FormGroup className="mb-3">
                    <Label>{t("Last change request time")}</Label>
                    &nbsp;
                    {isLoading ? (
                        <Spinner size="xs" />
                    ) : (
                        <>
                            :&nbsp;
                            <span className={msgStyle}>
                                {lastChangeRequestTimeMsg}
                            </span>
                        </>
                    )}
                </FormGroup>
            </Row>
        </>
    )
}

export default withTranslation()(RawPostFormStatus)
