import axios from "axios"
import MockAdapter from "axios-mock-adapter"
import * as url from "./url_helper"

import {
    deviceExample1,
    deviceExample2,
    deviceExample3,
    devicesList,
    fakeOperationalReport,
    fakeStatusReport,
    fakePendingDcuData,
    fakePendingConfData,
    fakeBlob,
    globalConfig,
    presetsExample,
} from "../common/data"
import { fakeBlobsList } from "src/common/data/changes"

const users = [
    {
        id: 1,
        userName: "bejsce@auron.studio",
        email: "bejsce@auron.studio",
        firstName: "bejsce",
        lastName: "miejsce",
        phone: "1234567890",
        role: "user",
        password: "4Pk4yNUo",
        token: "dummy",
    },
    {
        id: 2,
        userName: "admin@auron.studio",
        email: "admin@auron.studio",
        firstName: "admin",
        lastName: "istrator",
        phone: "0987654321",
        role: "admin",
        password: "287opVMC",
        token: "dummy",
    },
    {
        id: 3,
        userName: "tarlow@auron.studio",
        email: "tarlow@auron.studio",
        firstName: "jan",
        lastName: "tarlowski",
        phone: "123123123",
        role: "user",
        password: "ee4lc3xy",
        token: "dummy",
    },
    {
        id: 4,
        userName: "admin2@auron.studio",
        email: "admin2@auron.studio",
        firstName: "admin2",
        lastName: "istrator2",
        phone: "09876543212",
        role: "admin",
        password: "287opVMC",
        token: "dummy",
    },
]

const mailResult = {
    status: "sent",
}

const apiTimeout = 1000

function getQueryParameters(url: string) {
    const queryString = url.split("?")[1] // Get the query string part of the URL
    const queryParams = new URLSearchParams(queryString)
    return Object.fromEntries(queryParams.entries())
}

function filterValidUser(user: any) {
    const validUsersEnv = process.env["REACT_APP_VALIDUSERS"] ?? null
    const validUsersArray = validUsersEnv?.split(",").map((item) => item.trim())
    const validateUser =
        validUsersArray == null ||
        validUsersArray.find((str) => str === user.email)
    //console.log(user, validUsersEnv, validUsersArray, validateUser)
    const validUser = users.filter(
        (usr) =>
            usr.email === user.email &&
            usr.password === user.password &&
            validateUser
    )
    return validUser
}

const fakeDataBackend = () => {
    let realFetch = window.fetch
    window.fetch = function (_url, opts) {
        const urlStr = _url.toString()
        const { method, headers } = opts
        console.log("Call via fakeDataBackend: ", urlStr, opts, method, headers)
        return new Promise((resolve, reject) => {
            // wrap in timeout to simulate server api call
            setTimeout(handleRoute, apiTimeout)
            function handleRoute() {
                switch (true) {
                    case urlStr.endsWith(url.GET_APP_CONFIG) &&
                        (!method || method === "GET"):
                        return ok(globalConfig)
                    case urlStr.endsWith(url.GET_DEVICES_LIST) &&
                        (!method || method === "GET"):
                        return ok(devicesList)
                    case urlStr.includes(url.POST_DEVICE_DCU) &&
                        method === "POST":
                        const getDeviceData = getQueryParameters(urlStr)
                        const deviceId = getDeviceData["id"].toString()
                        console.log(
                            `Fake POST got into deviceId ${deviceId}. url=${urlStr}`
                        )
                        return ok(deviceExample1)
                    case urlStr.includes(url.POST_DEVICE_CONF) &&
                        method === "POST":
                        return ok(deviceExample1)
                    case urlStr.includes(url.GET_PRESETS) &&
                        method === "DELETE":
                        //console.log("Returning presetsExample")
                        const presetId = getQueryParameters(urlStr)["id"]
                        console.log(
                            `Fake DELETE presetId ${presetId}. url=${urlStr}`
                        )
                        const newDcus = [...presetsExample.dcus]
                        const dcuIndex = presetsExample.presets.findIndex(
                            (e) => e.value === presetId
                        )
                        newDcus.splice(dcuIndex, 1)
                        //returning table with deleted value
                        return ok({
                            presets: presetsExample.presets.filter(
                                (p: any) => p.value != presetId
                            ),
                            dcus: newDcus,
                        })
                    case urlStr.includes(url.GET_PRESETS) &&
                        (!method || method === "GET"):
                        //console.log("Returning presetsExample")
                        return ok(presetsExample)
                    case urlStr.includes(url.GET_PRESETS) && method === "POST":
                        //console.log("Returning presetsExample")
                        return ok(presetsExample)
                    case urlStr.includes(url.GET_DEVICE) &&
                        (!method || method === "GET"): {
                        //from data=dcu&id=868110060139621 -> getId
                        const deviceId =
                            getQueryParameters(urlStr)["id"].toString()
                        console.log(
                            `Fake GET got into deviceId ${deviceId}. url=${urlStr}`
                        )
                        if (deviceId == "868110060141601") {
                            //console.log("Returning deviceExample2")
                            return ok(deviceExample2)
                        }
                        if (deviceId == "868110060139621") {
                            //console.log("Returning deviceExample3")
                            return ok(deviceExample3)
                        }
                        //console.log("Returning deviceExample1")
                        return ok(deviceExample1)
                    }
                    case urlStr.includes(url.GET_STATUS_REPORT) &&
                        (!method || method === "GET"):
                        return ok(fakeStatusReport)
                    case urlStr.includes(url.POST_EMAIL_STATUS_REPORT) &&
                        method === "POST":
                        return ok(mailResult)
                    case urlStr.includes(url.GET_OPERATIONAL_REPORT) &&
                        (!method || method === "GET"):
                        return ok(fakeOperationalReport)
                    case urlStr.includes(url.POST_EMAIL_OPERATIONAL_REPORT) &&
                        method === "POST":
                        return ok(mailResult)
                    case urlStr.includes(url.GET_DCU_PENDING_CHANGES) &&
                        (!method || method === "GET"):
                        return ok(fakePendingDcuData)
                    case urlStr.includes(url.GET_CONF_PENDING_CHANGES) &&
                        (!method || method === "GET"):
                        return ok(fakePendingConfData)
                    case urlStr.includes(url.GET_BLOB) &&
                        (!method || method === "GET"):
                        return ok(fakeBlob)
                    case urlStr.includes(url.GET_BLOB) && method === "DELETE":
                        return ok(fakeBlob)
                    case urlStr.includes(url.LIST_BLOBS) &&
                        (!method || method === "GET"):
                        return ok(fakeBlobsList)
                    default:
                        console.log(
                            "pass any requests not handled into real requests API"
                        )
                        return realFetch(_url, opts)
                            .then((response) => resolve(response))
                            .catch((error) => reject(error))
                }
            }

            function ok(body: any) {
                resolve({
                    arrayBuffer: null,
                    json: () => Promise.resolve(body),
                    blob: null,
                    headers: null,
                    ok: true,
                    status: 200,
                    statusText: "OK",
                    redirected: false,
                    type: "basic",
                    clone: null,
                    body: null,
                    bodyUsed: null,
                    formData: null,
                    url: null,
                    text: () => Promise.resolve(JSON.stringify(body)),
                })
            }
        })
    }
}

const fakeAuthBackend = () => {
    const mock = new MockAdapter(axios, { onNoMatch: "passthrough" })

    mock.onPost(url.POST_JWT_LOGIN).reply((config) => {
        const user = JSON.parse(config["data"])
        const validUser = filterValidUser(user)
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (validUser.length === 1) {
                    resolve([200, validUser[0]])
                } else {
                    reject([401, "Error401"])
                }
            })
        })
    })

    mock.onPost(url.POST_JWT_LOGOUT).reply((config) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve([200, {}])
            })
        })
    })

    mock.onPost(url.POST_JWT_CHANGE_PASSWORD).reply((config) => {
        // User needs to check that user is eXist or not and send mail for Reset New password
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve([200, "Your password has been changed successfully"])
            })
        })
    })

    mock.onPost(url.POST_JWT_PERM).reply((data) => {
        //Always say say!
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve([200, true])
            })
        })
    })

    mock.onPost(url.POST_JWT_EDIT_PROFILE).reply((config) => {
        const user = JSON.parse(config["data"])
        const validUser = users.filter((usr) => usr.id === user.id)

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (validUser["length"] === 1) {
                    //Find index of specific object using findIndex method.
                    const objIndex = users.findIndex(
                        (obj) => obj.id === user.id
                    )
                    //Update object's name property.
                    users[objIndex].userName = user.userName
                    // Assign a value to locastorage
                    localStorage.removeItem("authUser")
                    localStorage.setItem(
                        "authUser",
                        JSON.stringify(users[objIndex])
                    )
                    resolve([200, "Profile Updated Successfully"])
                } else {
                    reject([400, "Something wrong for edit profile"])
                }
            })
        })
    })

    mock.onPost(url.POST_JWT_CHANGE_PASSWORD).reply((config) => {
        // User needs to check that user is eXist or not and send mail for Reset New password

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve([200, "Check you mail and reset your password."])
            })
        })
    })
}

export { fakeAuthBackend, fakeDataBackend }
